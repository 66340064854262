import * as _ from "lodash";
import { DateTime } from "luxon";
import Car from "../interfaces/carDetails.interface";

import { TimeSort } from "../components/Features/Catalog/catalogUtils";
import { EditPostStoreName } from "../app/store/postFormSlice";
import { actions as analyticsActions } from "../app/store/analyticsFormSlice";
import { actions as editFormActions } from "../app/store/editFormSlice";
import { actions as postFormActions } from "../app/store/postFormSlice";
import { ElasticCriteria } from "../app/store/storeTypes";

/**
 * @param {number} number - number to be converted
 * @param {string[]} titles - array of titles for 1, 2, 5
 * @returns {string} - converted number with title
 * createLabel(selectedAdsIds.length, ['объявление', 'объявления', 'объявлений'])}
 */
export const createLabel = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${
        titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
    }`;
};

export const createOffersLabel = (number: number) => {
    return createLabel(number, ["объявление", "объявления", "объявлений"]);
};

/**
 *
 * @param any[] arr - array to get random item from
 * @returns random item from array
 */
export const getRandomItemFromArray = (arr: any[]) => {
    return arr[Math.floor(Math.random() * arr.length)];
};

/**
 * @param {string} str - string to be converted
 * @returns {string} - converted string
 * */
export const isValidEmail = (email: string) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export const camelToUnderscore = (key: string) => {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.split(" ").join("_").toLowerCase();
};

export const getCamelCaseInsensitive = (object: any, key: string) => {
    let camelVal = undefined;
    let snakeVal = undefined;
    if (key.includes("_")) {
        snakeVal = object[_.camelCase(key)];
        camelVal = object[key];
    } else {
        snakeVal = object[camelToUnderscore(key)];
        camelVal = object[key];
    }

    if (snakeVal) return snakeVal;
    if (camelVal) return camelVal;
    return undefined;
};

export const getTime = (key: string) => {
    let res: any = null;

    if (key === TimeSort.Day) {
        res = DateTime.now().minus({ days: 1 });
    }

    if (key === TimeSort.Hour) {
        res = DateTime.now().minus({ hours: 1 });
    }

    if (key === TimeSort.ThreeHours) {
        res = DateTime.now().minus({ hours: 3 });
    }

    if (key === TimeSort.Week) {
        res = DateTime.now().minus({ days: 7 });
    }

    if (key === TimeSort.Month) {
        res = DateTime.now().minus({ days: 30 });
    }

    // if (key === TimeSort.TwoMonths) {
    //     res = DateTime.now().minus({ days: 60 });
    // }

    // if (key === TimeSort.SixMonths) {
    //     res = DateTime.now().minus({ days: 180 });
    // }

    if (key === TimeSort.AllTime) {
        res = DateTime.now().minus({ days: 10000 }).toString();
    }

    // debugger
    const finalRes = res.toString().slice(0, -10);
    return finalRes;
};

export const numberWithSpaces = (x: string | number, returnZero = false) => {
    if (!x) {
        return "";
    }
    const res = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (res === "0" && returnZero) return "";
    if (res === "0" && !returnZero) return "0";
    return res;
};

export const getAddress = (offer: Car) => {
    const address = [offer.city, offer.address].filter(Boolean).join(", ");
    return address;
};

export const convertObjToUrlParams = (obj: any, searchParam = new URLSearchParams()) => {
    Object.entries(obj).forEach(([k, v]) => {
        if (v !== null && typeof v === "object") {
            convertObjToUrlParams(v, searchParam);
        } else {
            // @ts-ignore
            searchParam.append(k, v);
        }
    });

    return searchParam;
};

const ruCollator = new Intl.Collator("en-EN");
export const sortByName = (a: any, b: any) => {
    return (
        // @ts-ignore
        /^[0-9]/.test(a) - /^[0-9]/.test(b) || ruCollator.compare(a, b)
    );
};

const prefixNumber = (str: string) => {
    if (str === "7") {
        return "7 (";
    }
    if (str === "8") {
        return "8 (";
    }
    if (str === "9") {
        return "7 (9";
    }
    return "7 (";
};

export const formatToPhone = (val: string) => {
    const value = val.replace(/\D+/g, "");
    const numberLength = 11;

    let result;
    if (val.includes("+8") || val[0] === "8") {
        result = "";
    } else {
        result = "+";
    }

    for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
            case 0:
                result += prefixNumber(value[i]);
                continue;
            case 4:
                result += ") ";
                break;
            case 7:
                result += "-";
                break;
            case 9:
                result += "-";
                break;
            default:
                break;
        }
        result += value[i];
    }
    return result;
};

export const forceToFloat = (valueIn: any) => {
    const value = valueIn.toString();
    const result = parseFloat(value.replace(/\s/g, "").replace(",", "."));
    return result;
};

export const isSuccessHTTPCode = (status: number) => {
    return status >= 200 && status < 300;
};

export const formatDate = (date: string) => {
    //Итоговый формат: 9 августа 2023 г. в 12:08

    const dt = DateTime.fromISO(date);
    const f: any = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    };
    let transformedDate = dt.setLocale("ru").toLocaleString(f);
    return `${transformedDate}`;
};

export const digitalKeyBoardPattern = "[0-9]*";
export const dateKeyBoardPattern = "d{1,2}/d{1,2}/d{4}";

export const capitalize = (word: string) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
};

export const printNumber = (value: number | null | undefined) => {
    if (value === null || value === undefined) {
        return "";
    } else if (value === 0) {
        return "0";
    } else {
        return numberWithSpaces(value);
    }
};

export const getFileObjectFromURL = async (url: string) => {
    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], "test.jpg");
    return file;
};

export interface KeyValObj {
    [key: string]: string | number | boolean | null | undefined;
}

export interface String2StringObj {
    [key: string]: string;
}

export const renderPrice = (val: number) => {
    return `${numberWithSpaces(val)} ₽`;
};

export const getActions = (storeName: EditPostStoreName) => {
    switch (storeName) {
        case "postForm":
            return postFormActions;
        case "editForm":
            return editFormActions;
        case "analyticsForm":
            return analyticsActions;
    }
};

export const generateBackendFilters = (params: ElasticCriteria) => {
    let filters: any = {};

    if (params.brand) {
        filters.brand = params.brand;
    }

    if (params.model) {
        filters.model = params.model;
    }

    if (params.generation) {
        filters.generation = params.generation.generation;
        filters.restyle = params.generation.restyleNumber;
    }

    return filters;
};

export const generateElasticFilters = (params: ElasticCriteria) => {
    let filters: any = {
        query: {
            bool: {
                must: []
            }
        }
    };

    if (params.brand) {
        filters.query.bool.must.push({
            match_phrase: { brand: params.brand }
        });
    }

    if (params.model) {
        filters.query.bool.must.push({
            match_phrase: { model: params.model }
        });
    }

    if (params.generation) {
        filters.query.bool.must.push({
            match_phrase: { generation_number: params.generation.generation }
        });
        filters.query.bool.must.push({
            match_phrase: { restyle_number: params.generation.restyleNumber }
        });
    }

    return filters;
};

export const getYMap = () => {
    // @ts-ignore
    const ymaps = window.ymaps;
    if (!ymaps) {
        return undefined;
    }

    return ymaps;
};

export const NOT_FILLED = "не заполнено";

export const getSavedCity = () => {
    if (typeof window === "undefined") return [];
    const savedCity = localStorage.getItem("city");
    if (savedCity) {
        return [savedCity];
    } else {
        return [];
    }
};

export const addBanners = (page?: number): Array<number> => {
    return Array(page)
        .fill(0)
        .map((_, i) => i * 19);
};